document.addEventListener("DOMContentLoaded", () => {
    const html = document.documentElement;
    const themeIconDesktop = document.getElementById("themeIconDesktop");
    const themeIconMobile = document.getElementById("themeIconMobile");
    const themeMenuDesktop = document.getElementById("themeMenuDesktop");
    const themeMenuMobile = document.getElementById("themeMenuMobile");
    const modeToggleDesktop = document.getElementById("modeToggleDesktop");
    const modeToggleMobile = document.getElementById("modeToggleMobile");


    // Toggle the theme menu for desktop and mobile independently
    modeToggleDesktop?.addEventListener("click", () => {
        themeMenuDesktop?.classList.toggle("hidden");
    });

    modeToggleMobile?.addEventListener("click", () => {
        themeMenuMobile?.classList.toggle("hidden");
    });

    // Cerrar el menú al hacer clic fuera de él
    window.addEventListener("click", (event) => {
        if (!modeToggleDesktop?.contains(event.target)) {
            themeMenuDesktop?.classList.add("hidden");
        }
        if (!modeToggleMobile?.contains(event.target)) {
            themeMenuMobile?.classList.add("hidden");
        }
    });


    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "system") {
        const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
        html.classList.add(systemTheme);
        updateThemeIcon("system");
    } else if (savedTheme) {
        html.classList.add(savedTheme);
        updateThemeIcon(savedTheme);
    } else {
        const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
        html.classList.add(systemTheme);
        updateThemeIcon("system");
    }

    // Change the theme and save in localStorage
    document.getElementById("lightThemeDesktop")?.addEventListener("click", () => {
        html.classList.remove("dark");
        localStorage.setItem("theme", "light");
        updateThemeIcon("light");
        themeMenuDesktop?.classList.add("hidden"); // Hide the menu
    });

    document.getElementById("darkThemeDesktop")?.addEventListener("click", () => {
        html.classList.add("dark");
        localStorage.setItem("theme", "dark");
        updateThemeIcon("dark");
        themeMenuDesktop?.classList.add("hidden"); // Hide the menu
    });

    document.getElementById("systemThemeDesktop")?.addEventListener("click", () => {
        html.classList.remove("dark");
        const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
        html.classList.add(systemTheme);
        localStorage.setItem("theme", "system");
        updateThemeIcon("system");
        themeMenuDesktop?.classList.add("hidden"); // Hide the menu
    });

    function updateThemeIcon(theme) {
        const icon = window.innerWidth < 768 ? themeIconMobile : themeIconDesktop;
        if(icon){
            switch (theme) {
                case "light":
                    icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>weather-sunny</title><path fill="currentColor" d="M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,2L14.39,5.42C13.65,5.15 12.84,5 12,5C11.16,5 10.35,5.15 9.61,5.42L12,2M3.34,7L7.5,6.65C6.9,7.16 6.36,7.78 5.94,8.5C5.5,9.24 5.25,10 5.11,10.79L3.34,7M3.36,17L5.12,13.23C5.26,14 5.53,14.78 5.95,15.5C6.37,16.24 6.91,16.86 7.5,17.37L3.36,17M20.65,7L18.88,10.79C18.74,10 18.47,9.23 18.05,8.5C17.63,7.78 17.1,7.15 16.5,6.64L20.65,7M20.64,17L16.5,17.36C17.09,16.85 17.62,16.22 18.04,15.5C18.46,14.77 18.73,14 18.87,13.21L20.64,17M12,22L9.59,18.56C10.33,18.83 11.14,19 12,19C12.82,19 13.63,18.83 14.37,18.56L12,22Z" /></svg>`;
                    break;
                case "dark":
                    icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>weather-night</title><path fill="currentColor" d="M17.75,4.09L15.22,6.03L16.13,9.09L13.5,7.28L10.87,9.09L11.78,6.03L9.25,4.09L12.44,4L13.5,1L14.56,4L17.75,4.09M21.25,11L19.61,12.25L20.2,14.23L18.5,13.06L16.8,14.23L17.39,12.25L15.75,11L17.81,10.95L18.5,9L19.19,10.95L21.25,11M18.97,15.95C19.8,15.87 20.69,17.05 20.16,17.8C19.84,18.25 19.5,18.67 19.08,19.07C15.17,23 8.84,23 4.94,19.07C1.03,15.17 1.03,8.83 4.94,4.93C5.34,4.53 5.76,4.17 6.21,3.85C6.96,3.32 8.14,4.21 8.06,5.04C7.79,7.9 8.75,10.87 10.95,13.06C13.14,15.26 16.1,16.22 18.97,15.95M17.33,17.97C14.5,17.81 11.7,16.64 9.53,14.5C7.36,12.31 6.2,9.5 6.04,6.68C3.23,9.82 3.34,14.64 6.35,17.66C9.37,20.67 14.19,20.78 17.33,17.97Z" /></svg>`;
                    break;
                case "system":
                    icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>monitor</title><path fill="currentColor" d="M21,16H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10V20H8V22H16V20H14V18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z" /></svg>`;
                    break;
            }
        }
    }
});
